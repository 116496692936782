import { parse } from "url";
import Head from 'next/head';
import { Collection } from "authory-api-types/dist/types/collections";
import { getContentData, truncateClipWord } from "../../utils/article-text-utils";
import { ContentResponse, AuthenticatedUserResponse, UserResponse, CertificateResponse, ArticlePreviewResponse } from "authory-api-types/dist/types";
import { DEFAULT_OG_IMAGE } from "../../types/defaultOgImage";
import { getCollectionOGDisplayImageUrl } from "../../utils/getCollectionDisplayImageUrl";
import { getProfileOGDisplayImageUrl } from "../../utils/getPortfolioDisplayImageUrl";
import { CookieYesBanner } from "../CookieYesBanner";
import { useContext } from "react";
import { DomainContext, HostContext } from "../../context/domainContext";
import { RequestHost } from "../../types/headers";
import { getDisplayName } from "../../utils/getDisplayName";
import { getTwitterProfileComponent } from "../../utils/getTwitterProfileComponent";
import { getCertificateSeasonLabel, getCertificateTypeLabel } from "../../utils/getCertificateLabel";
import { CertificateType } from "authory-api-types/dist/enums";

const handleFromTwitterUrl = (url: string) => {
    try {
        const parsed = parse(url);
        const path = parsed.pathname;

        if (!path) return "@Authory";

        const idx = path.lastIndexOf("/");

        if (idx >= 0) {
            return `@${path.substring(idx + 1)}`;
        } else {
            return `@${path}`;
        }
    } catch {
        return "@Authory";
    }
}

export const getTitle = (target: SEO_TYPE, user: AuthenticatedUserResponse | UserResponse, article?: ContentResponse | ArticlePreviewResponse, collection?: Collection, certificate?: CertificateResponse) => {

    if (target === SEO_TYPE.PROFILE && user.ogTitle && user.ogTitle.trim() !== "") {
        return user.ogTitle;
    }

    if (target === SEO_TYPE.ARTICLE && article) {

        if (!!article.ogTitle?.length) {
            return `${article.ogTitle}`;
        }

        if (!!article.title?.length) {
            return `${article.title}`;
        }

        if (!!article.ogDescription?.length) {
            return `${truncateClipWord(article.ogDescription, 20)}`;
        }

        if (!!article.description?.length) {
            return `${truncateClipWord(article.description, 20)}`;
        }

        return `${getContentData(article.type).label} on ${article.sourceName}`;
    }

    if (target === SEO_TYPE.COLLECTION && collection) {
        return !!collection.label ? `${collection.label}` : "Collection details";
    }

    if (target === SEO_TYPE.CERTIFICATE && certificate) {
        return `${getDisplayName(user)}: ${getCertificateTypeLabel(certificate.type)} - ${getCertificateSeasonLabel(certificate.season)}`;
    }

    return `${getDisplayName(user)}'s Portfolio`;
}

export const getDescription = (target: SEO_TYPE, user: AuthenticatedUserResponse | UserResponse, article?: ContentResponse | ArticlePreviewResponse, collection?: Collection, certificate?: CertificateResponse) => {

    if (target === SEO_TYPE.PROFILE && user.ogDescription && user.ogDescription.trim() !== "") {
        return user.ogDescription;
    }

    if (target === SEO_TYPE.ARTICLE && article) {
        return article.ogDescription || article.description || "";
    }

    if (target === SEO_TYPE.COLLECTION && collection?.description) {
        return collection.description
    }

    if (target === SEO_TYPE.CERTIFICATE && certificate) {
        switch (certificate.type) {
            case CertificateType.experienceLeader:
                return "This certificate proves that the holder's experience in writing articles is significantly higher than the work experience of an average writer.";
            case CertificateType.humanWriter:
                return "This certificate proves that the holder's articles are written with a high degree of certainty by a human, not by artificial intelligence.";
            case CertificateType.longFormProfessional:
                return "This certificate proves that the holder is significantly more experienced writing long-form articles than the average writer.";
            case CertificateType.prolificExpert:
                return "This certificate proves that the holder is significantly more experienced in creating a continuous stream of articles than the average writer.";
            case CertificateType.socialMediaSpecialist:
                return "This certificate proves that the holder's articles receive significantly higher engagement on social media compared to the average article."
            default:
                return "";
        }
    }

    return `Find ${getDisplayName(user).trim()}'s content all in one single place and follow all new content pieces via email, regardless where they are published.`
}

const getOgType = (target: SEO_TYPE) => {
    switch (target) {
        case SEO_TYPE.PROFILE:
            return "profile";
        default:
            return "website";
    }
}

export const getImage = (target: SEO_TYPE, user: AuthenticatedUserResponse | UserResponse, article?: ContentResponse | ArticlePreviewResponse, collection?: Collection, certificate?: CertificateResponse) => {

    if (target === SEO_TYPE.PROFILE) {
        return getProfileOGDisplayImageUrl(user);
    }

    if (target === SEO_TYPE.ARTICLE) {
        if (article?.previewImage) {
            return article.previewImage;
        } else {
            return "";
        }
    }

    if (target === SEO_TYPE.COLLECTION && collection) {
        return getCollectionOGDisplayImageUrl(collection, user.slug);
    }

    if (target === SEO_TYPE.CERTIFICATE && certificate) {
        return `${process.env.NEXT_PUBLIC_CERTIFICATES_S3_BUCKET}/certificates/${certificate.uid}/og`;
    }

    return DEFAULT_OG_IMAGE;
}

const getImageWidth = (target: SEO_TYPE, article?: ContentResponse) => {
    if (article?.previewImageWidth) return article.previewImageWidth.toString()
    if (target === SEO_TYPE.CERTIFICATE) return "2000";
}

const getImageHeight = (target: SEO_TYPE, article?: ContentResponse) => {
    if (article?.previewImageHeight) return article.previewImageHeight.toString()
    if (target === SEO_TYPE.CERTIFICATE) return "1344";
}

const getDomainUrl = (user: AuthenticatedUserResponse | UserResponse, isMainDomain: boolean, host: string | null) => {
    return isMainDomain ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${user.slug}` : `https://${host}`;
}

const getUrl = (target: SEO_TYPE, user: AuthenticatedUserResponse | UserResponse, article?: ContentResponse, collection?: Collection, certificate?: CertificateResponse, isMainDomain = true, host: string | null = null) => {

    const domain = getDomainUrl(user, isMainDomain, host);

    if (target === SEO_TYPE.ARTICLE && article) {
        return article?.publishedOnAuthory ? `${domain}/${article?.canonicalSlug}` : (article?.originalUrl || "");
    }

    if (target === SEO_TYPE.PROFILE) {
        return domain;
    }

    if (target === SEO_TYPE.COLLECTION && collection) {
        return `${domain}/collection/${collection.canonicalSlug}`;
    }

    if (target === SEO_TYPE.CERTIFICATE && certificate) {
        return `${domain}/certificate/${certificate.uid}`;
    }

    return domain;
}

export enum SEO_TYPE {
    PROFILE = "PROFILE",
    ARTICLE = "ARTICLE",
    COLLECTION = "COLLECTION",
    CERTIFICATE = "CERTIFICATE",
}

type OGTagsProps = {
    user: AuthenticatedUserResponse | UserResponse,
    heading?: string,
    article?: ContentResponse,
    target: SEO_TYPE,
    collection?: Collection,
    certificate?: CertificateResponse,
    skipCookieYes?: boolean,
}

export const OGTags = ({ user, heading, target, article, collection, certificate, skipCookieYes = false }: OGTagsProps) => {
    const defaultTwitter = "@Authory";
    const requestHostHeader: RequestHost = useContext(DomainContext) ?? RequestHost.MAINDOMAIN;
    const host = useContext(HostContext);
    const isMainDomain = requestHostHeader === RequestHost.MAINDOMAIN;

    const description = getDescription(target, user, article, collection, certificate);
    const title = getTitle(target, user, article, collection, certificate);
    const image = getImage(target, user, article, collection, certificate);
    const type = getOgType(target);
    const imageWidth = getImageWidth(target, article);
    const imageHeight = getImageHeight(target, article);

    const twitterProfileComponent = getTwitterProfileComponent(user);
    const twitter = twitterProfileComponent && twitterProfileComponent.url?.length ? handleFromTwitterUrl(twitterProfileComponent.url) : defaultTwitter;

    const url = getUrl(target, user, article, collection, certificate, isMainDomain, host);

    return <>
        <Head>
            {heading ? <title>{heading} – Authory</title> : <title>{title}</title>}

            <meta name="description" content={description} />
            <meta name="og:description" content={description} />
            <meta name="twitter:description" content={description} />

            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />

            <meta property="og:image" content={image} />
            <meta property="og:image:secure" content={image} />
            <meta property="og:image:secure_url" content={image} />
            {!!imageWidth && <meta property="og:image:width" content={imageWidth} />}
            {!!imageHeight && <meta property="og:image:height" content={imageHeight} />}
            <meta name="og:url" content={url} />
            <meta name="og:type" content={type} />
            <meta name="og:first_name" content={user.firstName} />
            <meta name="og:last_name" content={user.lastName} />
            <meta name="og:site_name" content="Authory" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="canonical" href={url} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:site" content={twitter} />
            <meta name="twitter:creator" content={twitter} />
            {article ? (article?.publishedOnAuthory || !article.seoHidden) ? <></> : <meta name="robots" content="noindex" /> : <></>}
            {target === SEO_TYPE.CERTIFICATE && <meta name="robots" content="noindex" />}
        </Head>
        {isMainDomain && !skipCookieYes && <CookieYesBanner />}
    </>;
};
